import './product-slider.scss';

// import { Slider } from './../../components/slider/slider';
import { A11y, Navigation } from 'swiper';

class ProductSlider {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-productslider'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$productSlider = $element;
        this.$productSliderElement = this.$productSlider.querySelector('[' + this.settings.initAttr + '="slider"]');
        this.$productSliderSlides = this.$productSliderElement.querySelectorAll('[' + this.settings.initAttr + '="slide"]');
        this.productSlider = null;
        this.sliderActive = false;

        this.resizeHandler = window.resizeHandler;

        if (this.$productSliderSlides.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        this.checkSize();
        this.resizeHandler.customFunctions.push(() => {
            this.checkSize();
        });
    }

    checkSize () {
        if (window.innerWidth < 768 && this.$productSliderSlides.length > 1) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else if (window.innerWidth < 1024 && this.$productSliderSlides.length > 2) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else if (window.innerWidth < 1440 && this.$productSliderSlides.length > 3) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else if (window.innerWidth >= 1440 && this.$productSliderSlides.length > 4) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else {
            if (this.sliderActive === true) {
                this.destroySlider();
            }
        }
    }

    initSlider () {
        this.productSlider = new this.Slider(this.$productSliderElement, {
            modules: [A11y, Navigation],
            speed: 1000,
            slidesPerView: 'auto',
            slidesPerGroupAuto: 1,
            prevnext: true,
            initAttr: this.settings.initAttr,
            breakpoints: {
                768: {
                    slidesPerGroup: 2
                },
                1024: {
                    slidesPerGroup: 3
                },
                1440: {
                    slidesPerGroup: 4
                }
            },
            onInit: () => {
                if (window.allowAnimation === true) {
                    this.$productSlider.setAttribute('data-inview', '');
                    window.checkInview(window.eventScroller);
                }
            }
        });

        this.sliderActive = true;
    }

    destroySlider () {
        if (typeof this.productSlider !== 'undefined' && this.productSlider !== null) {
            this.productSlider.destroy();
            this.sliderActive = false;
        }
    }
}

export { ProductSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$productSlider = $context.querySelectorAll('[data-productslider="root"]');
        for (let i = 0; i < $$productSlider.length; i++) {
            const $productSlider = $$productSlider[i];

            const productSliderAPI = new ProductSlider($productSlider);
            $productSlider.API = productSliderAPI;
        }
    }
});
